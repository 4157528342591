import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


export default function Profil(props) {
    const navigate = useNavigate()
    const { isLoggedIn, isAdmin } = useAuth();
    const username = sessionStorage.getItem('username')

    const logout = () => {
        sessionStorage.clear()
        window.location.href = '/'
        return
    }

    console.log(props.isAdmin)

    if (isLoggedIn === null) {
        return (
            <span className="loader" />
        )
    } else if (isLoggedIn === false) {
        window.location.href = "/error401"
        return
    } else {
        return (
            <div className="profil-wrapper">
                <div className="profile">
                    <h1>Välkommen, {username}</h1>
                    <div className="profile-main">
                        <div className="profile-sub">
                            <button onClick={() => navigate('/protokoll')}>Protokoll</button>
                            {isAdmin && <button onClick={() => navigate('/skapa-anvandare')}>Skapa ny användare</button>}
                            {isAdmin && <button onClick={() => navigate('/medlemmar')}>Medlemmar</button>}
                        </div>
                        <div className="profile-sub">
                            <button onClick={() => navigate('/change-name')}>Ändra användarnamn</button>
                            <button onClick={() => navigate('/change-password')}>Ändra lösenord</button>
                            <button onClick={logout}>Logga ut</button>
                        </div>
                    </div>
                </div>
                {isAdmin && <button className="inlägg-knapp" onClick={() => navigate('/skapa-inlagg')}>Skapa inlägg</button>}
            </div>
        )
    }
}