import React, { useState } from 'react';
import { useAuth, webAddress } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function SkapaInlagg() {
    const { isAdmin } = useAuth();
    const [titel, setTitel] = useState('')
    const [inlagg, setInlagg] = useState('')
    const [messageStatus, setMessageStatus] = useState('')
    const [message, setMessage] = useState('')
    const [image, setImage] = useState(null)

    const navigate = useNavigate()

    if (!isAdmin) {
        window.location.href = "/error401"
        return
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0]

        if (file) {
            const validTypes = ['image/jpeg', 'image/png']
            if (!validTypes.includes(file.type)) {
                alert("Endast bilder av typen .jpg och .png tillåts.")
                e.target.value = null
                return
            }

            setImage(file)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const username = sessionStorage.getItem('username')
        const token = sessionStorage.getItem('authToken')

        const submitPostData = async (postData) => {
            try {
                const response = await axios.post(
                    `${webAddress}/api/admin/create-post`,
                    postData,
                    { withCredentials: true })

                    if (response.data.success) {
                        setMessageStatus('message-success')
                        setMessage('Inlägg skapat')
                    } else {
                        setMessageStatus('message-error')
                        setMessage('Något gick fel.')
                    }
                } catch (error) {
                    console.error('Error creating post:', error.message)
                    setMessageStatus('message-error')
                    setMessage('Något gick fel.')
                }
            }

        if (image) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64image = reader.result

                const postData = {
                    titel,
                    inlagg,
                    image: base64image,
                    username,
                    token,
                }

                submitPostData(postData)
            }

            reader.readAsDataURL(image)
        } else {
            const postData = {
                titel,
                inlagg,
                image: null,
                username,
                token,
            }

            submitPostData(postData)
        }
    }

    return (
        <div className="blogg">
            <button className="bak-knapp" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left" /></button>
            <h1>Skapa inlägg</h1>
            {message && <p className={messageStatus}>{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="blogg-sub">
                    <input 
                        type="text" 
                        placeholder="Titel" 
                        className="titel-input" 
                        onChange={(e) => setTitel(e.target.value)}
                    />
                    <input 
                        type="file" 
                        accept=".jpg, .jpeg, .png"
                        className="input-file"
                        onChange={handleImageChange}
                    />
                </div>
                <textarea 
                    placeholder="Inlägg" 
                    value={inlagg}
                    onChange={(e) => setInlagg(e.target.value)}
                />
                <button className="blogg-submit-button" type="submit">Skapa</button>
            </form>
        </div>
    )
}
