import React from 'react';

export default function FlygplanComp(props) {
  return (
    <div className="flygplan-comp">
        <img src={props.img} alt={props.name}/>
        <div className="flygplan-infoj">
          <div className="flygplan-namn">
              <h3>{props.reg}</h3>
              <p>{props.titel}</p>
          </div>
          <p>{props.info}</p>
        </div>
    </div>
  )
}