import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Error() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/')
    }
    return (
        <div className="error">
            <div className="error-main">
                <h1>401</h1>
            </div>
            <p>Åtkomst nekad</p>
            <button onClick={handleClick}>Startsidan</button>
        </div>
    )
}