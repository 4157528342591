import React from 'react';
import axios from 'axios';
import { webAddress } from '../context/AuthContext';

export default function ProtokollComp(props) {
    
    // har kommit nårmare men behöver fixa autentisering så att pdfen kan ses.
    // just nu är den låst bakom autentisering

    const requestPdf = async (filename) => {

        const username = sessionStorage.getItem('username')
        const token = sessionStorage.getItem('authToken')

        if (!username || !token) {
            return
        }

        try {
            console.log(filename)
            const response = await axios.post(`${webAddress}/api/request-pdf`, 
                { username, token, filename },
                { withCredentials: true });
            const { url } = response.data; // Get the generated URL
            
            if (url) {
                window.open(url, "_blank");
            } else {
                return
            }
        } catch (error) {
            console.error('Error requesting PDF:', error);
            // Handle error (show message to user)
        }
    };

    return (
        <div className="protocomp">
            <button onClick={() => requestPdf(props.filename)}>{props.pdfName}</button>
            <p>{props.date}</p>
            <p><i>- {props.name}</i></p>
        </div>
    )
}