import React, { useEffect, useState } from 'react';
import { useAuth, webAddress } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MedlemComp from "./MedlemComp";

export default function Medlemmar() {
    const navigate = useNavigate();
    const { isAdmin } = useAuth();
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)


    const fetchAllUsers = async () => {
        const token = sessionStorage.getItem('authToken')
        const username = sessionStorage.getItem('username')
        if (!token || !username) {
            return
        }

        try {
            const response = await axios.post(
                `${webAddress}/api/admin/fetch-all-users`,
                { username, token },
                { withCredentials: true })
            if (response.status === 200) {
                console.log(response.data)
                setUsers(response.data)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error('Error fetching users:', error.message)
            return false;
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isAdmin === null) {
            return
        } else if (!isAdmin) {
            window.location.href = "/error401"
        }
        fetchAllUsers()
    }, [isAdmin])
        
    if (loading) {
        return <p>Laddar...</p>
    }

    const updateAdminStatus = async (targetUsername, adminStatus) => {
        const token = sessionStorage.getItem('authToken')
        const requester = sessionStorage.getItem('username')
        
        if (!token || !requester) {
            return
        }

        try {
            const response = await axios.post(
                'http://192.168.0.106:5000/api/admin/toggle-admin',
                { 
                    username: requester, 
                    targetUsername,
                    adminStatus, 
                    token, 
                },
                { withCredentials: true })
            if (response.status === 200) {
                console.log(response.data)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error('Error updating admin status:', error.message)
        }
    }
    
    const filteredUsers = users.filter(user => user.username !== 'admin')

    return (
        <div className="medlemmar">
            <button className="bak-knapp" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left" /></button>
            <h1>Medlemmar</h1>
            <div className="medlem-grid">
                {users.length > 0 ? (
                    filteredUsers.map((user, index) => (
                        <MedlemComp 
                            key={index} 
                            user={user} 
                            updateAdminStatus={updateAdminStatus} 
                        />
                    ))
                ) : (
                    <p>Inga medlemmar hittades</p>
                )}
            </div>
        </div>
    )
}