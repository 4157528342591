import React, { useEffect, useState } from 'react';
import { useAuth, webAddress } from '../context/AuthContext';
import axios from 'axios';
import ProtokollComp from './ProtokollComp'
import { useNavigate } from 'react-router-dom';

export default function Protokoll() {
    const navigate = useNavigate()
    const { isLoggedIn, isAdmin } = useAuth();
    const [pdf, setPdf] = useState(null)
    const [protokoll, setProtokoll] = useState([])
    const [message, setMessage] = useState('')
    const [messageStatus, setMessageStatus] = useState('')
    const [filename, setFilename] = useState('')

    const fetchProtokoll = async () => {
        const username = sessionStorage.getItem('username')
        const token = sessionStorage.getItem('authToken')

        if (!username || !token) {
            setMessage('Ogiltig inloggning.')
            setMessageStatus('message-error')
            return
        }

        try {
            const response = await axios.post(
                `${webAddress}/api/fetch-protokoll`,
                { username, token },
                { withCredentials: true })
            if (response.status === 200) {
                if (response.data) {
                    const sortedProtokoll = response.data.sort((a,b) => new Date(b.date - a.date))
                    setProtokoll(sortedProtokoll)
                } else {
                    setProtokoll([
                        {
                            name: "Inget uppladdat ännu!",
                            protokoll: null
                        }
                    ])
                }
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error('Error fetching files:', error.message)
            return
        }
    }

    useEffect(() => {
        if (!isLoggedIn) {
            window.location.href = '/error401'
        } else {
            fetchProtokoll();
        }
    }, [isLoggedIn])

    const handleFileChange = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (!"application/pdf".includes(file.type)) {
                alert("Endast filer av typen .pdf tillåts.")
                e.target.value = null
                return
            }

            setPdf(file)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const username = sessionStorage.getItem('username')
        const token = sessionStorage.getItem('authToken')

        if (!username || !token) {
            setMessage('Fel vid autentisering.')
            setMessageStatus('message-error')
            return
        }

        const submitPostData = async (postData) => {
            try {
                const response = await axios.post(
                    'http://192.168.0.106:5000/api/admin/upload-pdf',
                    postData,
                    { withCredentials: true })

                    if (response.data.success) {
                        setMessageStatus('message-success')
                        setMessage('Protokoll uppladdat.')
                    } else {
                        setMessageStatus('message-error')
                        setMessage('Något gick fel.')
                    }
                } catch (error) {
                    console.error('Error creating post:', error.message)
                    setMessageStatus('message-error')
                    setMessage('Något gick fel.')
                }
            }

        if (pdf) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64pdf = reader.result

                const postData = {
                    pdf: base64pdf,
                    filename,
                    username,
                    token,
                }

                submitPostData(postData)
            }

            reader.readAsDataURL(pdf)
        } else {
            setMessage('Vänligen ladda upp en fil.')
            setMessageStatus('message-error')
        }
    }

    console.log(protokoll)

    return (
        <div className="protokoll">
            <button className="bak-knapp" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left" /></button>
            <h1>Protokoll</h1>
            <div className="protokoll-inner">
                {protokoll.map((proto) => {
                    const dateObject = new Date(proto.date)
                    const day = dateObject.getDate()
                    const month = dateObject.getMonth()
                    const year = dateObject.getFullYear()
                    const actualFile = proto.filename.replace(/\s+/g, '_') + '.pdf'
                    return (
                        <ProtokollComp 
                            key={proto.date}
                            date={`${day}/${month + 1}/${year}`}
                            filename={actualFile}
                            pdfName={proto.filename}
                            name={proto.name}
                        />
                    )
                })} 
                {isAdmin ? 
                    <div>
                        <p className={messageStatus}>{message}</p>
                        <form onSubmit={handleSubmit} className="protokoll-form">
                            <input 
                                type="text" 
                                placeholder="Protokollnamn" 
                                className="protokoll-input" 
                                value={filename}
                                onChange={(e) => setFilename(e.target.value)}
                            />
                            <input 
                                type="file" 
                                accept=".pdf" 
                                onChange={handleFileChange} 
                                className="input-file" 
                            />
                            <button type="submit">Ladda upp</button>
                        </form>
                    </div>
                : null
                }
            </div>
        </div>
    )
}