import React from 'react';
import FlygplanComp from './FlygplanComp';


export default function Flygplan() {

  const flygplanInfo = [
      {
          "reg": "SE-TLI",
          "namn": "Bergfalke IV",
          "info": "",
          "bild": "/SE-TLI.jpg"
      },
      {
          "reg": "SE-VPV",
          "namn": "Eurostar EV97",
          "info": "",
          "bild": "/SE-VPV.jpg"
      },
      {
          "reg": "SE-TSL",
          "namn": "Pilatus B4",
          "info": "",
          "bild": "/SE-TSL.jpg"
      }
  ]
  return (
    <div className="flygplan">
        <h1>Flygplan</h1>
        {flygplanInfo.map((flygplan) => {
            return <FlygplanComp key={flygplan.reg} reg={flygplan.reg} img={flygplan.bild} titel={flygplan.namn} info={flygplan.info} />
        })}
    </div>
  )
}