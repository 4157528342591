import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import Nyheter from "./pages/Nyheter";
import Flygplan from "./pages/Flygplan";
import Login from "./pages/Login";
import Profil from "./pages/Profil";
import Protokoll from "./pages/Protokoll";
import Error404 from "./pages/404"
import Error401 from "./pages/401"
import ChangeName from "./pages/profilsidor/Changename";
import ChangePassword from "./pages/profilsidor/Changepassword";
import Medlemmar from "./pages/adminsidor/Medlemmar";
import SkapaAnvandare from "./pages/adminsidor/SkapaAnvandare";
import SkapaInlagg from "./pages/adminsidor/SkapaInlagg";
import BloggInlagg from "./pages/BloggInlagg";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { AuthProvider, webAddress } from './context/AuthContext';
import './App.css';

function App() {
  const handleLogin = async (username, password) => {
    try {
      const response = await axios.post(
        `${webAddress}/login`,
        { username, password }, 
        { 
          headers: { 'Content-Type': 'application/json' }, 
          withCredentials: true
        })
      if (response.status === 200) {
        sessionStorage.setItem('authToken', response.data.token)
        sessionStorage.setItem('username', username)
        return true;
      } else {
        return false;
      } 
    } catch (error) {
      console.error('Error logging in:', error)
      return false;
    }
  }  

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header /> 
          <main className="main">
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/nyheter" element={<Nyheter />} />
              <Route path="/flygplan" element={<Flygplan />} />
              <Route path="/login" element={<Login handleLogin={handleLogin} />} />
              <Route path="/profil" element={<Profil />} />
              <Route path="/protokoll" element={<Protokoll />} />
              <Route path="/error401" element={<Error401 />} />
              <Route path="/change-name" element={<ChangeName />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/medlemmar" element={<Medlemmar />} />
              <Route path="/skapa-anvandare" element={<SkapaAnvandare />} />
              <Route path="/skapa-inlagg" element={<SkapaInlagg />} />
              <Route path="/blog/:slug" element={<BloggInlagg />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
