import React, { useEffect, useState } from 'react';
import { useAuth, webAddress } from '../../context/AuthContext';
import axios from 'axios';


export default function MedlemComp(props) {
    const [checked, setChecked] = useState(false)
    const [warning, setWarning] = useState(false)
    const { isSuperAdmin } = useAuth();
    const username = sessionStorage.getItem('username')

    const handleCheckbox = () => {
        setChecked(!checked)
        props.updateAdminStatus(props.user.username, checked ? 1 : 0) 
    }

    useEffect(() => {
        setChecked(props.user.admin === 1 ? true : false)
    }, [props.user.admin])

    const deleteUser = async (targetUsername) => {
        const token = sessionStorage.getItem('authToken')
        const username = sessionStorage.getItem('username')
        
        if (!token || !username) {
            return
        } else if (!targetUsername) {
            return
        }

        try {
            const response = await axios.post(
                `${webAddress}/api/admin/delete-user`,
                { username, token, targetUsername },
                { withCredentials: true })
            if (response.status === 200) {
                window.location.reload()
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error('Error deleting user:', error.message)
            return false;
        }
    }

    const handleDelete = () => {
        if (!warning) {
            setWarning(true)
        } else {
            deleteUser(props.user.username)
        }
    }

    return (
        <div className="medlem-comp">
            <div className="medlem-info">
                <div className="medlem-single">
                    <h1><b><u>Användarnamn:</u></b></h1>
                    <p>{props.user.username}</p>
                </div>
                <div className="medlem-single">
                    <h1><b><u>Namn:</u></b></h1>
                    <p>{props.user.name}</p>
                </div>
            </div>
            <div className="admin-status">
                <h1><b><u>Admin:</u></b></h1>
                <label className="custom-checkbox">
                    <input type="checkbox" 
                        checked={checked} 
                        onChange={handleCheckbox} 
                        style={{ display: 'none' }}
                        disabled={props.user.username === username ? true : false}
                        />
                    {props.user.username === username ? <i className="fa-solid fa-ban toggle-disabled" /> 
                    : checked ?
                        <i className="fa-solid fa-toggle-on toggle-on" />
                        : 
                        <i className="fa-solid fa-toggle-off toggle-off" />
                    }
                </label>
                {isSuperAdmin && 
                    <div className="super-admin-buttons">
                        <button 
                            style={{ backgroundColor: warning ? 'red' : null }}
                            onClick={handleDelete}
                        >
                                {warning ? 'Är du säker?' : 'Ta bort användare'}
                        </button>
                        <button>Ändra lösenord</button>
                    </div>
                }
            </div>
        </div>
    )
}