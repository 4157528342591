import React, { createContext, useContext, useState, useEffect } from 'react';
import { authorizeUser } from '../hooks/userAuth';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(null);

  useEffect(() => {
    authorizeUser({ setIsLoggedIn, setIsAdmin, setIsSuperAdmin });
  }, []);

  const value = {
    isLoggedIn,
    isAdmin,
    setIsLoggedIn,
    setIsAdmin,
    isSuperAdmin,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export const webAddress = 'http://localhost:5000';

export function useAuth() {
  return useContext(AuthContext);
}
