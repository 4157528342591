import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';


export default function ChangePassword() {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null)
    const [messageStatus, setMessageStatus] = useState(null)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword1, setNewPassword1] = useState('')
    const [newPassword2, setNewPassword2] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!oldPassword) {
            setMessage("Skriv in ditt gamla lösenord")
            setMessageStatus("message-error")
            return
        } else if (!newPassword1) {
            setMessage("Skriv in det nya lösenordet")
            setMessageStatus("message-error")
            return
        } else if (!newPassword2) {
            setMessage("Bekräfta det nya lösenordet")
            setMessageStatus("message-error")
            return
        } else if (newPassword1 !== newPassword2) {
            setMessage("Det nya lösenordet matchar inte")
            setMessageStatus("message-error")
            return
        }

        try {
            const token = sessionStorage.getItem('authToken')
            const username = sessionStorage.getItem('username')
            const response = await axios.post(
                '/api/change-password',
                { 
                    username,
                    oldPassword, 
                    newPassword: newPassword1,
                    token 
                },
                {
                    withCredentials: true
                })
            console.log(response)
            if (response.data.success === true) {
                setMessage('Lösenordet har uppdaterats')
                setMessageStatus("message-success")
                console.log(response.data.message)
            } else {
                setMessage("Ditt lösenord matchar inte")
                setMessageStatus("message-error")
                console.log(response.data.message)
            }
        } catch (error) {
            console.error('Error changing password:', error.message)
            setMessage("Något gick fel. Försök igen.")
            setMessageStatus("message-error")
        }
    }

    return (
        <div className="change">
            <button className="bak-knapp" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left" /></button>
            <h1>Ändra lösenord</h1>
            {message && <p className={messageStatus}>{message}</p>}
            <form onSubmit={handleSubmit}>
                <input 
                    type="password" 
                    placeholder="Gammalt lösenord" 
                    value={oldPassword} 
                    onChange={(e) => setOldPassword(e.target.value)} 
                />
                <input 
                    type="password"
                    placeholder="Nytt lösenord"
                    value={newPassword1}
                    onChange={(e) => setNewPassword1(e.target.value)}
                />
                <input 
                    type="password"
                    placeholder="Bekräfta nytt lösenord"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                />
                <button type="submit" className="skicka-knapp">Skicka</button>
            </form>
        </div>
    )
}