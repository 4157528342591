import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';


export default function ChangeName(props) {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null)
    const [messageStatus, setMessageStatus] = useState(null)
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [newUsername, setNewUsername] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!password) {
            setMessage("Skriv in ditt lösenord")
            setMessageStatus('message-error')
            return
        } else if (!newUsername) {
            setMessage("Skriv in ditt nya användarnamn")
            setMessageStatus('message-error')
            return
        } else if (password !== passwordConfirmation) {
            setMessage("Ditt lösenord matchar inte")
            setMessageStatus('message-error')
            return
        }

        try {
            const token = sessionStorage.getItem('authToken')
            const username = sessionStorage.getItem('username')
            const response = await axios.post(
                '/api/change-username',
                {
                    username,
                    newUsername,
                    password,
                    token
                },
                {
                    withCredentials: true
                })
            if (response.data.success === true) {
                sessionStorage.setItem('username', newUsername)
                setMessage('Användarnamnet har uppdaterats')
                setMessageStatus('message-success')
            } else {
                setMessage('Användarnamnet används redan')
                setMessageStatus('message-error')
            }
        } catch (error) {
            console.error('Error changing username:', error.message)
            setMessage('Något gick fel. Försök igen.')
            setMessageStatus('message-error')
        }
    } 

    return (
        <div className="change">
            <button className="bak-knapp" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left" /></button>
            <h1>Ändra användarnamn</h1>
            {message && <p className={messageStatus}>{message}</p>}
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    placeholder="Nytt användarnamn" 
                    value={newUsername} 
                    onChange={(e) => setNewUsername(e.target.value)} 
                />
                <input 
                    type="password"
                    placeholder="Lösenord"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input 
                    type="password"
                    placeholder="Bekräfta lösenord"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                <button type="submit" className="skicka-knapp">Skicka</button>
            </form>
        </div>
    )
}