import React from 'react';
import { useState } from 'react'

export default function Login(props) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loginMessage, setLoginMessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!username) {
            setLoginMessage("Skriv in ditt användarnamn")
            return
        } else if (!password) {
            setLoginMessage("Skriv in ditt lösenord")
            return
        }
        try {
            const success = await props.handleLogin(username.trim(), password.trim())
            if (success) {
                window.location.href = '/profil'
            }
            if (!success) {
                setLoginMessage("Inloggning misslyckades")
            }
        } catch (error) {
            console.error('Error logging in:', error)
            setLoginMessage("Ett oväntat fel inträffade. Försök igen.")
        }
    }

    return (
        <div className="login">
            <h1>Logga in</h1>
            {loginMessage && <p className={"login-message"}>{loginMessage}</p>}
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    placeholder="Användarnamn" 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input 
                    type="password" 
                    placeholder="Lösenord" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Skicka</button>
            </form>
        </div>
    );
}