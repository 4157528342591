import axios from 'axios';
import React, { useEffect, useState } from 'react';
import InlaggComp from './InlaggComp';
import { useNavigate } from 'react-router-dom';
import generateSlug from "./utils";
import { webAddress } from '../context/AuthContext';

export default function Nyheter() {
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();


    const fetchPosts = async () => {
        try {
            const response = await axios.get(
                `${webAddress}/fetch-posts`,
                { withCredentials: true })
            if (response.status === 200) {
                if (response.data) {
                    const sortedPosts = response.data.sort((a,b) => new Date(b.date) - new Date(a.date))
                    setPosts(sortedPosts)
                } else {
                    setPosts([
                        {
                            title: 'Inga inlägg att visa',
                            inlagg: 'Inga inlägg att visa',
                        }
                    ])
                }
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error('Error fetching posts:', error.message)
            return
        }
    }

    const handleClick = (post) => {
        const slug = generateSlug(post.titel)
        navigate(`/blog/${encodeURIComponent(slug)}`, { state: { post } })
    }

    useEffect(() => {
        fetchPosts();
    }, [])

    return (
        <div className="nyheter">
            <h1>Inlägg</h1>
                {posts.map((post) => {
                    const dateObject = new Date(post.date)
                    const day = dateObject.getDate()
                    const month = dateObject.getMonth()
                    const year = dateObject.getFullYear()
                    return (
                        <InlaggComp 
                            key={post.date}
                            title={post.titel}
                            date={`${day}/${month + 1}/${year}`}
                            name={post.name}
                            inlagg={post.inlagg}
                            image={`${webAddress}/images/${post.image}`}
                            handleClick={() => handleClick(post)}
                        />
                    )})
                }
        </div>
    )
}