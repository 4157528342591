import React from 'react';

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer-kontakt">
                <p>Nolby Flygfält</p>
                <p>661 92 Säffle</p>
                <p>Ordförande Johan Lund:</p>
                <p>&middot; 070 932 28 09</p>
                <p>&middot; ordforande@saffleflygklubb.se</p>
            </div>
            <div className="footer-kontakt-links">
                <a href="https://www.facebook.com/profile.php?id=100070978911250" target="_blank"><i className="fa-brands fa-facebook-square"></i></a>
                <a href="https://www.instagram.com/explore/locations/1031426807/saffle-flygklubb/" target="_blank"><i className="fa-brands fa-instagram"></i></a>
            </div>
        </div>
    )
}