import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { webAddress } from '../context/AuthContext';

export default function BloggInlagg() {
    const [post, setPost] = useState(null);
    const navigate = useNavigate();
    const { slug } = useParams();

  useEffect(() => {
        // Fetch post data based on the slug (ID or unique identifier)
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${webAddress}/fetch-post/${slug}`);
                if (response.status === 200 && response.data) {
                    setPost(response.data);
                } else {
                    // Handle error or redirect to the news page if post is not found
                    navigate("/nyheter");
                }
            } catch (error) {
                console.error('Error fetching post:', error);
                navigate("/nyheter"); // Redirect if there's an error
            }
        };
        fetchPost();
    }, [slug, navigate]);

    if (!post) {
        return <div>Loading...</div>;
    }
    
    const dateObject = new Date(post?.date)

    return (

        <div className="blogg-inlagg">
            <button className="bak-knapp" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left" /></button>
            <h1>{post.titel}</h1>
            <p className="inlagg-date">Inlagt: {dateObject.getDate()}/{dateObject.getMonth() + 1}/{dateObject.getFullYear()}</p>
            {post.image !== null && <img src={`http://192.168.0.106:5000/images/${post.image}`} alt="" />}
            <pre>{post.inlagg}</pre>
            <p className="inlagg-signature">{post.name}, {dateObject.getDate()}/{dateObject.getMonth() + 1}/{dateObject.getFullYear()}</p>
        </div>
    )
}