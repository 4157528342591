import React from 'react';

export default function Index() {
    return (
        <div className="index-page"> 
            <div className="img-container">
                <img className="img-index" src="flygplatsen.png" alt="BFK-SE-LOY-170617-2-sign-1800x675.jpg" />
            </div>
            <h1>Välkommen till Säffle Flygklubb!</h1>
            <p>
                Klubben bildades 1961 och bedriver sin verksamhet på Nolby flygfält, 5 km söder om Säffle. 
                <br></br>Idag bedrivs verksamheten främst med det ultralätta motorplanet SE-VPV, som är en tvåsitsig Evektor Eurostar EV97. 
                <br></br>Verksamheten inkluderar även segelflyg och då med klubbens Bergfalke IV, samt en privatägd Pilatus B4.  
            </p>
        </div>
    );
}