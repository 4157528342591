import React from 'react';
import { webAddress } from '../context/AuthContext';

export default function InlaggComp(props) {
    const nullValue = `${webAddress}/images/null`
    return (
        <div className="inlagg-wrapper">
            <h1 onClick={props.handleClick}>{props.title}</h1>
            <div className="inlagg">
                <pre>{props.inlagg.slice(0, 42)}...</pre>
                {props.image !== nullValue && <img src={props.image} className="inlagg-img-fade" alt="" />}
            </div>
            <p className="inlagg-signature">{props.name}, {props.date}</p>
        </div>
    )
}